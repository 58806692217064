/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import womanWaving from '../../images/heroes/woman-waving.jpg'
import medicareAdvantageFormula from '../../images/icons/medicare-advantage-formula.svg'

import { Phone } from 'emotion-icons/material'

import {
  Banner,
  Hero,
  LinkButton,
  List,
  ListItem,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import isCallCenterOpen from '../../../../../utils/isOpen'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'

const Home = ({ data }) => {
  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(data?.allBrandyDisclaimer?.edges),
        seo: {
          title: ' Aetna Medicare Direct ',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: ' Aetna Medicare Direct ',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero background-image-position-right"
              image={<img src={womanWaving} alt="elderly woman waving" />}
              mainContent={
                <>
                  <Typography variant="h1" color="light">
                    Get More Coverage, More Benefits, and More Care{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <VariableContent
          backgroundColor="lightGray"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                What Is Aetna Medicare Advantage?
              </Typography>
              <img
                src={medicareAdvantageFormula}
                alt="Medicare Part A + Medicare Part B + More = Medicare Advantage formula illustration"
                className="margin-bottom-md"
              />
            </>
          }
        >
          <Typography variant="body">
            Why pay for a plan without benefits you need? Aetna’s Medicare
            Advantage plans cover all the same health services as Original
            Medicare, but with additional benefits. Talk to a licensed agent
            today to find a plan near you that fits your needs and your budget.
          </Typography>
          <Typography variant="body">
            In addition to Original Medicare benefits, Aetna Medicare Advantage
            plans may include coverage for:
          </Typography>
          <List>
            <ListItem>Vision care</ListItem>
            <ListItem>Dental care</ListItem>
            <ListItem>Health and wellness programs</ListItem>
            <ListItem>Gym memberships</ListItem>
          </List>
          {isCallCenterOpen() ? (
            <Banner
              backgroundColor="Primary"
              className="cta-banner column-desktop"
              mainContent={
                <>
                  <Typography
                    variant="h5"
                    style={{ color: 'white', marginBottom: '5px' }}
                  >
                    Talk to a Licensed Agent
                  </Typography>
                  <Typography
                    variant="body"
                    style={{ color: 'white', marginBottom: '10px' }}
                  >
                    TTY: 711, available 24/7
                  </Typography>
                  <LinkButton
                    variant="feature"
                    to={`tel:${rotatedNumber}`}
                    color="light"
                    className="margin-x-auto"
                    leftIcon={<Phone />}
                    requestId={require}
                  >
                    {rotatedNumber}
                  </LinkButton>
                </>
              }
            />
          ) : (
            <LinkButton
              requestId={requestId}
              to="https://www.sunfirematrix.com/app/consumer/clearlink/18572496/"
            >
              Find Medicare Plans Near You
            </LinkButton>
          )}
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
